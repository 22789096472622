<template>
  <div class="versions-handler">
    <!-- versions -->
    <KonectaDropdown :click-outside="outside" v-if="versionsPopup">
      <h4 class="mt-2 ml-4">
        {{ lang.botMaker.botEditor.navBar.tooltips.fork[languageSelected] }}
      </h4>
      <BotVersions
        class="mt-4 ml-2 mr-2"
        :select-version="selectVersion"
        :add-version="addVersion"
        :edit-version="editVersion"
        :promote-version="promoteVersion"
        :promote-to-test-version="promoteToTestVersion"
        :delete-version="deleteVersion"
        :read-only="readOnly"
      />
    </KonectaDropdown>
    <!-- versions -->

    <!-- new version -->
    <vs-popup
      :title="lang.surveyMaker.versions.addPopup.title[languageSelected]"
      :active.sync="newVersion.popup"
    >
      <form-wizard
        ref="newVersionFormWizard"
        color="rgba(var(--vs-primary), 1)"
        :title="
          lang.surveyMaker.versions.addPopup.formWizzard.title[languageSelected]
        "
        :subtitle="
          lang.surveyMaker.versions.addPopup.formWizzard.subtitle[
            languageSelected
          ]
        "
        :nextButtonText="
          lang.surveyMaker.versions.addPopup.formWizzard.nextButtonText[
            languageSelected
          ]
        "
        :backButtonText="
          lang.surveyMaker.versions.addPopup.formWizzard.backButtonText[
            languageSelected
          ]
        "
        :finishButtonText="
          lang.surveyMaker.versions.addPopup.formWizzard.finishButtonText[
            languageSelected
          ]
        "
        @on-complete="saveNewVersion"
      >
        <tab-content
          class="mb-5"
          :title="
            lang.surveyMaker.versions.addPopup.formWizzard.fork.title[
              languageSelected
            ]
          "
          icon="feather icon-git-branch"
        >
          <div class="vx-row">
            <vs-col vs-justify="center" vs-align="center" vs-w="12">
              <span class="mb-0" style="font-size: 12px; color: grey">
                {{
                  lang.surveyMaker.versions.addPopup.formWizzard.fork.table
                    .botLabel[languageSelected]
                }}&nbsp;
              </span>
              <vs-table
                v-if="newVersion"
                class="mt-4"
                v-model="newVersion.versionSelected"
                pagination
                max-items="5"
                sorted
                :data="botVersions"
                :no-data-text="lang.general.noDataText[languageSelected]"
              >
                <template slot="thead">
                  <vs-th sort-key="tag">
                    {{ lang.surveyMaker.versions.table.tag[languageSelected] }}
                  </vs-th>
                  <vs-th sort-key="active">
                    {{
                      lang.surveyMaker.versions.table.status[languageSelected]
                    }}
                  </vs-th>
                  <vs-th sort-key="_updatedAt">
                    {{
                      lang.surveyMaker.versions.table._updatedAt[
                        languageSelected
                      ]
                    }}
                  </vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr
                    :data="tr"
                    :key="indextr"
                    v-for="(tr, indextr) in data"
                  >
                    <vs-td :data="data[indextr].tag">
                      {{ data[indextr].tag }}
                    </vs-td>

                    <vs-td :data="data[indextr].active">
                      <vs-chip :color="data[indextr].active ? 'success' : ''">
                        <div v-if="data[indextr].active">
                          {{lang.surveyMaker.production[languageSelected]}}
                        </div>
                        <div v-else-if="!data[indextr].active && data[indextr].status !== 'qa'">
                          {{lang.surveyMaker.development[languageSelected]}}
                        </div>
                        <div v-else-if="!data[indextr].active && data[indextr].status === 'qa'">
                          {{lang.surveyMaker.test[languageSelected]}}
                        </div>
                      </vs-chip>
                    </vs-td>
                    <vs-td :data="data[indextr]._updatedAt">
                      {{ new Date(data[indextr]._updatedAt) | date }}
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
              <vs-divider />
              <div v-if="newVersion.versionSelected">
                <vs-chip
                  color="primary"
                  closable
                  @click="newVersion.versionSelected = null"
                >
                  {{ `${newVersion.versionSelected.tag}` }}
                </vs-chip>
              </div>
            </vs-col>
          </div>
        </tab-content>

        <tab-content
          class="mb-5"
          :title="
            lang.surveyMaker.versions.addPopup.formWizzard.settings.title[
              languageSelected
            ]
          "
          icon="feather icon-settings"
        >
          <div class="vx-row">
            <vs-col vs-justify="center" vs-align="center" vs-w="12">
              <vs-input
                @input="errorMsg = []"
                :label="
                  lang.surveyMaker.versions.addPopup.formWizzard.settings.tag
                    .labelCompulsory[languageSelected]
                "
                class="mb-6 w-full"
                :placeholder="
                  lang.surveyMaker.versions.addPopup.formWizzard.settings.tag
                    .placeholder[languageSelected]
                "
                v-model="newVersion.tag"
              />
              <div
                class="error-message height-fit-content my-3 text-center"
                v-if="errorMsg && errorMsg.length"
              >
                <p v-for="(errorObj, index) in errorMsg" :key="index">
                  {{ errorObj }}
                </p>
              </div>
              <div
                v-if="
                  bot &&
                    bot.nlu &&
                    (bot.nlu.platform === 'luis' ||
                      bot.nlu.platform === 'watson')
                "
                class="mt-4"
              >
                <EngineCredentialsTable :isEditing="false" />
              </div>
            </vs-col>
          </div>
          <!-- NO DEJAMOS QUE LO PONGA EN PRODUCCIÓN DE UNA
          PORQUE LAS INTENCIONES Y ENTIDADES SE TARDAN EN SER COPIADAS
          Y ENTRENADAS -->
          <!-- <vs-divider />
          <div class="vx-row">
            <vs-row>
              <vs-col class="w-full mb-2">
                <span class="mb-4" style="font-size: 12px; color: grey">
                  &nbsp;{{
                    lang.surveyMaker.versions.addPopup.formWizzard.settings
                      .status.label[languageSelected]
                  }}
                </span>
              </vs-col>
            </vs-row>
            <vs-row class="mb-0">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-xs="6"
                vs-sm="6"
                vs-lg="6"
              >
                <vs-card
                  class="center no-select not-allowed-option"
                  calss="bg--primary-gradient"
                  style="cursor: not-allowed"
                >
                  <div>
                    <h3 :class="{ 'active-title': newVersion.production }">
                      {{ lang.surveyMaker['production'][languageSelected] }}
                    </h3>
                    <br />
                    <feather-icon
                      color="success"
                      icon="CheckCircleIcon"
                      svgClasses="w-8 h-8"
                    ></feather-icon>
                  </div>
                </vs-card>
              </vs-col>

              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-xs="6"
                vs-sm="6"
                vs-lg="6"
              >
                <vs-card
                  :class="{ 'active-option': !newVersion.production }"
                  class="center no-select"
                  calss="bg--primary-gradient"
                >
                  <div>
                    <h3 :class="{ 'active-title': !newVersion.production }">
                      {{ lang.surveyMaker['development'][languageSelected] }}
                    </h3>
                    <br />
                    <feather-icon
                      color="success"
                      icon="Edit3Icon"
                      svgClasses="w-8 h-8"
                    ></feather-icon>
                  </div>
                </vs-card>
              </vs-col>
            </vs-row>
            <vs-row v-if="newVersion.production">
              <vs-col class="w-full mb-2">
                <span class="mb-4 text-danger" style="font-size: 12px">
                  {{
                    lang.surveyMaker.versions.addPopup.formWizzard.settings
                      .status.alert[languageSelected]
                  }}
                </span>
              </vs-col>
            </vs-row>
            <vs-row v-if="newVersion.production && bot.noIntentAction && bot.noIntentAction.key == 'jump'">
              <vs-col class="w-full mb-2">
                <span class="mb-4 text-danger" style="font-size: 12px">
                  {{
                    lang.surveyMaker.versions.addPopup.formWizzard.settings
                      .status.alertHasJump[languageSelected]
                  }}
                </span>
              </vs-col>
            </vs-row>
          </div> -->
        </tab-content>
      </form-wizard>
    </vs-popup>
    <!-- new version -->

    <!-- edit version -->
    <vs-popup
      :title="lang.surveyMaker.versions.editPopup.title[languageSelected]"
      :active.sync="editingVersion.popup"
    >
      <div class="vx-row">
        <vs-col vs-justify="center" vs-align="center" vs-w="12">
          <vs-input
            :label="
              lang.surveyMaker.versions.addPopup.formWizzard.settings.tag
                .labelCompulsory[languageSelected]
            "
            class="mb-5 w-full"
            :placeholder="
              lang.surveyMaker.versions.addPopup.formWizzard.settings.tag
                .placeholder[languageSelected]
            "
            v-model="editingVersion.tag"
          />
        </vs-col>
      </div>
      <vs-divider />
      <div class="vx-row">
        <vs-row>
          <vs-col class="w-full mb-2">
            <span class="mb-4" style="font-size: 12px; color: grey">
              &nbsp;{{
                lang.surveyMaker.versions.addPopup.formWizzard.settings.status
                  .label[languageSelected]
              }}
            </span>
          </vs-col>
        </vs-row>
        <vs-row class="mb-0">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-xs="6"
            vs-sm="6"
            vs-lg="6"
          >
            <vs-card
              :class="{ 'active-option': editingVersion.production }"
              class="center no-select"
              calss="bg--primary-gradient"
              style="cursor: pointer"
            >
              <div @click="toggleEditVersionProduction(true)">
                <h3 :class="{ 'active-title': editingVersion.production }">
                  {{ lang.surveyMaker['production'][languageSelected] }}
                </h3>
                <br />
                <feather-icon
                  color="success"
                  icon="CheckCircleIcon"
                  svgClasses="w-8 h-8"
                ></feather-icon>
              </div>
            </vs-card>
          </vs-col>

          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-xs="6"
            vs-sm="6"
            vs-lg="6"
          >
            <vs-card
              :class="{ 'active-option': !editingVersion.production }"
              class="center no-select"
              calss="bg--primary-gradient"
              style="cursor: pointer"
            >
              <div @click="toggleEditVersionProduction(false)">
                <h3 :class="{ 'active-title': !editingVersion.production }">
                  {{ lang.surveyMaker['development'][languageSelected] }}
                </h3>
                <br />
                <feather-icon
                  color="success"
                  icon="Edit3Icon"
                  svgClasses="w-8 h-8"
                ></feather-icon>
              </div>
            </vs-card>
          </vs-col>
        </vs-row>
        <vs-row v-if="editingVersion.production">
          <vs-col class="w-full mb-2">
            <span class="mb-4 text-danger" style="font-size: 12px">
              {{
                lang.surveyMaker.versions.addPopup.formWizzard.settings.status
                  .alert[languageSelected]
              }}
            </span>
          </vs-col>
        </vs-row>
      </div>
      <div class="vx-row">
        <vs-row>
          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="space-between"
            vs-align="center"
          >
            <vs-button @click="cancelEditVersion" color="danger" type="flat">
              {{
                lang.surveyMaker.versions.editPopup.form.cancel[
                  languageSelected
                ]
              }}
            </vs-button>
            <vs-button @click="saveEditVersion">
              {{
                lang.surveyMaker.versions.editPopup.form.save[languageSelected]
              }}
            </vs-button>
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
    <!-- edit version -->
    <!-- </vs-popup> -->
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import EngineCredentialsTable from '../bot-maker/add-bot/EngineCredentials/EngineCredentialsTable'

export default {
  name: 'VersionsHandler',
  props: {
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      errorMsg: [],
      navbarItems: [],
      newVersion: {
        popup: false,
        tag: '',
        production: false,
        versionSelected: null
      },
      editingVersion: {
        popup: false,
        tag: null,
        production: null,
        versionSelected: null
      },
      versionsPopup: false,
      versionSelected: null
    }
  },
  components: {
    FormWizard,
    EngineCredentialsTable,
    TabContent,
    BotVersions: () => import('@/components/BotVersions.vue'),
    KonectaDropdown: () => import('@/components/KonectaDropdown.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', [
      'bot',
      'botVersions',
      'activeVersionData',
      'activeVersion'
    ]),
    ...mapGetters('credentials', ['nlu'])
  },
  watch: {
    'bot.credential'(newVal) {
      this.bot.credential = newVal
    }
  },
  methods: {
    ...mapMutations('botMaker', ['SET_VERSION']),
    ...mapActions('botMaker', [
      'GET_BOT_VERSIONS',
      'GET_ACTIVE_VERSION',
      'CREATE_BOT_VERSION',
      'PROMOTE_BOT_VERSION',
      'PROMOTE_TO_TEST_BOT_VERSION',
      'DELETE_BOT_VERSION',
      'GET_DIALOGS',
      'GET_INTENTIONS',
      'GET_API_CALLS'
    ]),
    ...mapActions('bots', ['GET_VERSION_INTENTS']),
    getForkPercentage(fork) {
      if (fork.status === 0) {
        return 100
      }
      let total = (fork.intents || []).length + (fork.entities || []).length
      if (!total) {
        // si es 0
        // no hay nada que copiar
        // pero por algún motivo no terminó aún
        // puede ser por un error
        // o por algún otro motivo.
        return null
      }
      let done =
        (fork.intents || []).filter(item => item.status === 0).length +
        (fork.entities || []).filter(item => item.status === 0).length
      if (done === total) {
        // ya se copiaron las entidades y versiones, le queda un loop más al worker
        return 99
      }
      if (done === 0) {
        // devolvemos 1% porque 0% queda feo.
        return 1
      }
      return Math.round((done * 100) / total)
    },
    async isForking(version) {
      let ok = true
      let notify
      if (
        version.fork &&
        version.fork.status !== 0 // no terminó
      ) {
        ok = false
        // traemos la versión de nuevo para ver si ya terminó de hacer el fork
        await this.GET_BOT_VERSIONS([this.bot.service.id])
        const updatedVersion = this.botVersions.find(
          item => item._id === version._id
        )
        if (!updatedVersion) {
          // ocurrió algo inesperado, no se encontró la versión
          // mostramos un error y no cambiamos la versión
          notify = {
            color: 'danger',
            title: this.lang.botMaker.botEditor.navBar.versions
              .selectVersionFail.title[this.languageSelected],
            text: this.lang.botMaker.botEditor.navBar.versions.selectVersionFail
              .text[this.languageSelected]
          }
        } else if (updatedVersion.fork && updatedVersion.fork.status === -1) {
          // ocurrió un error
          notify = {
            color: 'danger',
            title: this.lang.botMaker.botEditor.navBar.versions
              .selectVersionFail.title[this.languageSelected],
            text: this.lang.botMaker.botEditor.navBar.versions.selectVersionFail
              .text[this.languageSelected]
          }
        } else if (updatedVersion.fork && updatedVersion.fork.status !== 0) {
          // todavía no se terminó de hacer el fork
          // mostramos un warning y no cambiamos la versión
          const percentage = this.getForkPercentage(updatedVersion.fork)
          notify = {
            color: 'warning',
            title: this.lang.botMaker.botEditor.navBar.versions.forkInProgress
              .title[this.languageSelected],
            text:
              this.lang.botMaker.botEditor.navBar.versions.forkInProgress.text[
                this.languageSelected
              ] + (percentage !== null ? percentage + '%.' : '')
          }
        } else {
          // terminó el fork, cambiamos la versión
          ok = true
        }
      }
      return { ok, notify }
    },
    open() {
      this.versionsPopup = true
    },
    async reloadIntents(activeVersion) {
      await this.GET_VERSION_INTENTS(activeVersion)
    },
    async selectVersion(version) {
      if (version._id !== this.bot.activeVersion) {
        this.$vs.loading({
          text: this.lang.botMaker.botEditor.navBar.versions.selectVersion[
            this.languageSelected
          ],
          textAfter: true
        })
        try {
          const { ok, notify } = await this.isForking(version)
          if (ok) {
            this.SET_VERSION({
              versionId: version._id,
              versionFork: version.fork
            })
            await this.GET_API_CALLS(this.bot.activeVersion)
            await this.GET_INTENTIONS()
            await this.GET_DIALOGS()
            await this.reloadIntents(this.activeVersion)
            // this.versionsPopup = false
            this.$router.push({
              query: {
                ...this.$route.query,
                version: this.activeVersion
              }
            })
          }
          this.$vs.loading.close()
          if (notify) {
            this.$vs.notify(notify)
          }
        } catch (error) {
          console.error(error)
          this.$vs.loading.close()
        }
      }
    },
    addVersion() {
      this.newVersion.tag = ''
      this.newVersion.versionSelected = null
      this.$refs.newVersionFormWizard.reset()
      this.versionsPopup = false
      this.newVersion.popup = true
    },
    async saveNewVersion() {
      if (!this.newVersion.tag) {
        this.errorMsg = [
          this.lang.botMaker.botEditor.navBar.versions.newVersion.labelRequired[
            this.languageSelected
          ]
        ]
        return
      }
      this.$vs.loading({
        text: this.lang.botMaker.botEditor.navBar.versions.newVersion.loading[
          this.languageSelected
        ],
        textAfter: true
      })
      try {
        let payload = {
          serviceId:
            this.$route.query.serviceId || this.$route.query.service || null,
          forkingBot: this.newVersion.versionSelected,
          tag: this.newVersion.tag
        }
        if (this.bot.credential) {
          payload.credential = this.bot.credential
        }
        const versionResponse = await this.CREATE_BOT_VERSION(payload)

        this.$vs.loading.close()
        if (this.newVersion.production) {
          await this.askForChangeIntegrations(versionResponse)
        }

        this.newVersion.tag = ''
        this.newVersion.production = false
        this.newVersion.popup = false
        this.$refs.newVersionFormWizard.reset()
        this.newVersion.popup = false

        this.versionsPopup = false

        this.$vs.notify({
          color: 'success',
          text: this.lang.botMaker.botEditor.navBar.versions.messages
            .createVersion.success[this.languageSelected]
        })
      } catch (error) {
        this.$vs.notify({
          color: 'danger',
          text: this.lang.botMaker.botEditor.navBar.versions.messages
            .createVersion.error[this.languageSelected]
        })
        console.error(error)
        this.newVersion.popup = false
        this.$vs.loading.close()
      }
    },
    toggleNewVersionProduction(newVal) {
      this.newVersion.production = newVal
    },
    async editVersion(version) {
      try {
        this.editingVersion.tag = version.tag
        this.editingVersion.production = version.active
        this.editingVersion.popup = true
      } catch (error) {
        this.$vs.notify({
          title: this.lang.surveyMaker.versions.editPopup.notify.getVersions
            .error.title[this.languageSelected],
          text: this.lang.surveyMaker.versions.editPopup.notify.getVersions
            .error.text[this.languageSelected],
          color: 'danger',
          position: 'top-right'
        })
      }
    },
    async saveEditVersion() {
      console.warn(this.editingVersion)
    },
    cancelEditVersion() {
      this.editingVersion.popup = false
    },
    toggleEditVersionProduction(newVal) {
      this.editingVersion.production = newVal
    },
    async promoteVersion(version) {
      try {
        const { ok, notify } = await this.isForking(version)
        if (notify) {
          this.$vs.notify(notify)
        }
        if (ok) {
          if (
            this.bot.noIntentAction &&
            this.bot.noIntentAction.key == 'jump'
          ) {
            this.$vs.dialog({
              type: 'confirm',
              color: 'warning',
              title: this.lang.botMaker.botEditor.navBar.versions.messages
                .promoteVersion.confirm.title[this.languageSelected],
              text: this.lang.botMaker.botEditor.navBar.versions.messages.promoteVersion.confirm.textWithJump[
                this.languageSelected
              ].replace('XXX', version.tag),
              acceptText: this.lang.treeView.tree.confirmation.acceptText[
                this.languageSelected
              ],
              cancelText: this.lang.treeView.tree.confirmation.cancelText[
                this.languageSelected
              ],
              accept: () => this.askForChangeIntegrations(version)
            })
          } else {
            this.$vs.dialog({
              type: 'confirm',
              color: 'warning',
              title: this.lang.botMaker.botEditor.navBar.versions.messages
                .promoteVersion.confirm.title[this.languageSelected],
              text: this.lang.botMaker.botEditor.navBar.versions.messages.promoteVersion.confirm.text[
                this.languageSelected
              ].replace('XXX', version.tag),
              acceptText: this.lang.treeView.tree.confirmation.acceptText[
                this.languageSelected
              ],
              cancelText: this.lang.treeView.tree.confirmation.cancelText[
                this.languageSelected
              ],
              accept: () => this.askForChangeIntegrations(version)
            })
          }
        }
      } catch (error) {
        this.$vs.loading.close()
      }
    },
    async promoteToTestVersion(version) {
      try {
        const { ok, notify } = await this.isForking(version)

        let confirmText = ''
        if (notify) {
          this.$vs.notify(notify)
        }
        if (ok) {
          if (
            this.bot.noIntentAction &&
            this.bot.noIntentAction.key == 'jump'
          ) {
            confirmText = this.lang.botMaker.botEditor.navBar.versions.messages.promoteToTestVersion.confirm.textWithJump[
              this.languageSelected
            ].replace('XXX', version.tag)
          } else {
            confirmText = this.lang.botMaker.botEditor.navBar.versions.messages.promoteToTestVersion.confirm.text[
              this.languageSelected
            ].replace('XXX', version.tag)
          }

          this.$vs.dialog({
            type: 'confirm',
            color: 'warning',
            title: this.lang.botMaker.botEditor.navBar.versions.messages
              .promoteToTestVersion.confirm.title[this.languageSelected],
            text: confirmText,
            acceptText: this.lang.treeView.tree.confirmation.acceptText[
              this.languageSelected
            ],
            cancelText: this.lang.treeView.tree.confirmation.cancelText[
              this.languageSelected
            ],
            accept: () => this.savePromoteToTestVersion(version)
          })
        }
      } catch (error) {
        this.$vs.loading.close()
      }
    },
    async savePromoteToTestVersion(version) {
      const versionId = version._id
      this.versionsPopup = false
      this.$vs.loading()
      try {
        await this.PROMOTE_TO_TEST_BOT_VERSION(versionId)
        this.$vs.notify({
          color: 'success',
          text: this.lang.botMaker.botEditor.navBar.versions.messages
            .promoteVersion.success[this.languageSelected]
        })
        this.$vs.loading.close()
      } catch (error) {
        console.error(error)
        this.$vs.notify({
          color: 'danger',
          text: this.lang.botMaker.botEditor.navBar.versions.messages
            .promoteVersion.error[this.languageSelected]
        })
        this.$vs.loading.close()
      }
    },
    askForChangeIntegrations(version) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: this.lang.botMaker.botEditor.navBar.versions.messages
          .promoteVersion.confirm.title[this.languageSelected],
        text: this.lang.botMaker.botEditor.navBar.versions.messages.promoteVersion.changeIntegrations[
          this.languageSelected
        ],
        acceptText: this.lang.treeView.tree.confirmation.acceptText[
          this.languageSelected
        ],
        cancelText: this.lang.treeView.tree.confirmation.cancelText[
          this.languageSelected
        ],
        accept: () => this.savePromoteVersion(version, true),
        cancel: () => this.savePromoteVersion(version, false)
      })
    },
    async savePromoteVersion(version, changeIntegrations) {
      const versionId = version._id
      this.versionsPopup = false
      this.$vs.loading()
      try {
        await this.PROMOTE_BOT_VERSION({ versionId, changeIntegrations })
        this.$vs.notify({
          color: 'success',
          text: this.lang.botMaker.botEditor.navBar.versions.messages
            .promoteVersion.success[this.languageSelected]
        })
        this.$vs.loading.close()
      } catch (error) {
        console.error(error)
        this.$vs.notify({
          color: 'danger',
          text: this.lang.botMaker.botEditor.navBar.versions.messages
            .promoteVersion.error[this.languageSelected]
        })
        this.$vs.loading.close()
      }
    },
    deleteVersion(version) {
      if (this.bot.activeVersion === version._id) {
        this.$vs.notify({
          color: 'warning',
          text: this.lang.botMaker.botEditor.navBar.versions.messages
            .deleteVersion.errorActive[this.languageSelected]
        })
        return
      }
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: this.lang.botMaker.botEditor.navBar.versions.messages
          .deleteVersion.confirm.title[this.languageSelected],
        text: this.lang.botMaker.botEditor.navBar.versions.messages.deleteVersion.confirm.text[
          this.languageSelected
        ].replace('XXX', version.tag),
        acceptText: this.lang.treeView.tree.confirmation.acceptText[
          this.languageSelected
        ],
        cancelText: this.lang.treeView.tree.confirmation.cancelText[
          this.languageSelected
        ],
        accept: () => this.removeVersion(version)
      })
    },
    async removeVersion(version) {
      const versionId = version._id
      this.versionsPopup = false
      this.$vs.loading()
      try {
        await this.DELETE_BOT_VERSION(versionId)
        this.$vs.notify({
          color: 'success',
          text: this.lang.botMaker.botEditor.navBar.versions.messages
            .deleteVersion.success[this.languageSelected]
        })
        this.$vs.loading.close()
      } catch (error) {
        console.error(error)
        this.$vs.notify({
          color: 'danger',
          text: this.lang.botMaker.botEditor.navBar.versions.messages
            .deleteVersion.error[this.languageSelected]
        })
        this.$vs.loading.close()
      }
    },
    outside: function() {
      this.versionsPopup = false
    },
    async loadVersions() {
      if (!this.activeVersionData && this.bot) {
        this.$vs.loading()
        try {
          const serviceId = this.bot.service.id
            ? this.bot.service.id
            : this.bot._botService.id
          await this.GET_ACTIVE_VERSION([serviceId, this.$route.query.version])
          await this.GET_BOT_VERSIONS([serviceId, this.$route.query.version])
        } catch (e) {
          console.error(e)
        }
        this.$vs.loading.close()
      }
    }
  },
  beforeMount() {
    this.loadVersions()
  }
}
</script>

<style lang="scss" scoped>
.versions-handler {
  position: relative;
}
.vs-dropdown--menu--after {
  right: 0px;
}
.konecta-dropdown {
  left: 20px;
  top: 10px;
}
.vs-dropdown--custom {
  // left: 205px;
  max-width: 440px;
  min-height: 556px;
}
.active-option {
  background: #ff9f38 !important;
  color: white;
}
.not-allowed-option {
  background: #f8f8f8;
}
.active-title {
  color: white;
}
</style>
