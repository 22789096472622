var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"versions-handler"},[(_vm.versionsPopup)?_c('KonectaDropdown',{attrs:{"click-outside":_vm.outside}},[_c('h4',{staticClass:"mt-2 ml-4"},[_vm._v("\n      "+_vm._s(_vm.lang.botMaker.botEditor.navBar.tooltips.fork[_vm.languageSelected])+"\n    ")]),_vm._v(" "),_c('BotVersions',{staticClass:"mt-4 ml-2 mr-2",attrs:{"select-version":_vm.selectVersion,"add-version":_vm.addVersion,"edit-version":_vm.editVersion,"promote-version":_vm.promoteVersion,"promote-to-test-version":_vm.promoteToTestVersion,"delete-version":_vm.deleteVersion,"read-only":_vm.readOnly}})],1):_vm._e(),_vm._v(" "),_c('vs-popup',{attrs:{"title":_vm.lang.surveyMaker.versions.addPopup.title[_vm.languageSelected],"active":_vm.newVersion.popup},on:{"update:active":function($event){_vm.$set(_vm.newVersion, "popup", $event)}}},[_c('form-wizard',{ref:"newVersionFormWizard",attrs:{"color":"rgba(var(--vs-primary), 1)","title":_vm.lang.surveyMaker.versions.addPopup.formWizzard.title[_vm.languageSelected],"subtitle":_vm.lang.surveyMaker.versions.addPopup.formWizzard.subtitle[
          _vm.languageSelected
        ],"nextButtonText":_vm.lang.surveyMaker.versions.addPopup.formWizzard.nextButtonText[
          _vm.languageSelected
        ],"backButtonText":_vm.lang.surveyMaker.versions.addPopup.formWizzard.backButtonText[
          _vm.languageSelected
        ],"finishButtonText":_vm.lang.surveyMaker.versions.addPopup.formWizzard.finishButtonText[
          _vm.languageSelected
        ]},on:{"on-complete":_vm.saveNewVersion}},[_c('tab-content',{staticClass:"mb-5",attrs:{"title":_vm.lang.surveyMaker.versions.addPopup.formWizzard.fork.title[
            _vm.languageSelected
          ],"icon":"feather icon-git-branch"}},[_c('div',{staticClass:"vx-row"},[_c('vs-col',{attrs:{"vs-justify":"center","vs-align":"center","vs-w":"12"}},[_c('span',{staticClass:"mb-0",staticStyle:{"font-size":"12px","color":"grey"}},[_vm._v("\n              "+_vm._s(_vm.lang.surveyMaker.versions.addPopup.formWizzard.fork.table
                  .botLabel[_vm.languageSelected])+" \n            ")]),_vm._v(" "),(_vm.newVersion)?_c('vs-table',{staticClass:"mt-4",attrs:{"pagination":"","max-items":"5","sorted":"","data":_vm.botVersions,"no-data-text":_vm.lang.general.noDataText[_vm.languageSelected]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,attrs:{"data":tr}},[_c('vs-td',{attrs:{"data":data[indextr].tag}},[_vm._v("\n                    "+_vm._s(data[indextr].tag)+"\n                  ")]),_vm._v(" "),_c('vs-td',{attrs:{"data":data[indextr].active}},[_c('vs-chip',{attrs:{"color":data[indextr].active ? 'success' : ''}},[(data[indextr].active)?_c('div',[_vm._v("\n                        "+_vm._s(_vm.lang.surveyMaker.production[_vm.languageSelected])+"\n                      ")]):(!data[indextr].active && data[indextr].status !== 'qa')?_c('div',[_vm._v("\n                        "+_vm._s(_vm.lang.surveyMaker.development[_vm.languageSelected])+"\n                      ")]):(!data[indextr].active && data[indextr].status === 'qa')?_c('div',[_vm._v("\n                        "+_vm._s(_vm.lang.surveyMaker.test[_vm.languageSelected])+"\n                      ")]):_vm._e()])],1),_vm._v(" "),_c('vs-td',{attrs:{"data":data[indextr]._updatedAt}},[_vm._v("\n                    "+_vm._s(_vm._f("date")(new Date(data[indextr]._updatedAt)))+"\n                  ")])],1)})}}]),model:{value:(_vm.newVersion.versionSelected),callback:function ($$v) {_vm.$set(_vm.newVersion, "versionSelected", $$v)},expression:"newVersion.versionSelected"}},[_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"sort-key":"tag"}},[_vm._v("\n                  "+_vm._s(_vm.lang.surveyMaker.versions.table.tag[_vm.languageSelected])+"\n                ")]),_vm._v(" "),_c('vs-th',{attrs:{"sort-key":"active"}},[_vm._v("\n                  "+_vm._s(_vm.lang.surveyMaker.versions.table.status[_vm.languageSelected])+"\n                ")]),_vm._v(" "),_c('vs-th',{attrs:{"sort-key":"_updatedAt"}},[_vm._v("\n                  "+_vm._s(_vm.lang.surveyMaker.versions.table._updatedAt[
                      _vm.languageSelected
                    ])+"\n                ")])],1)],2):_vm._e(),_vm._v(" "),_c('vs-divider'),_vm._v(" "),(_vm.newVersion.versionSelected)?_c('div',[_c('vs-chip',{attrs:{"color":"primary","closable":""},on:{"click":function($event){_vm.newVersion.versionSelected = null}}},[_vm._v("\n                "+_vm._s(("" + (_vm.newVersion.versionSelected.tag)))+"\n              ")])],1):_vm._e()],1)],1)]),_vm._v(" "),_c('tab-content',{staticClass:"mb-5",attrs:{"title":_vm.lang.surveyMaker.versions.addPopup.formWizzard.settings.title[
            _vm.languageSelected
          ],"icon":"feather icon-settings"}},[_c('div',{staticClass:"vx-row"},[_c('vs-col',{attrs:{"vs-justify":"center","vs-align":"center","vs-w":"12"}},[_c('vs-input',{staticClass:"mb-6 w-full",attrs:{"label":_vm.lang.surveyMaker.versions.addPopup.formWizzard.settings.tag
                  .labelCompulsory[_vm.languageSelected],"placeholder":_vm.lang.surveyMaker.versions.addPopup.formWizzard.settings.tag
                  .placeholder[_vm.languageSelected]},on:{"input":function($event){_vm.errorMsg = []}},model:{value:(_vm.newVersion.tag),callback:function ($$v) {_vm.$set(_vm.newVersion, "tag", $$v)},expression:"newVersion.tag"}}),_vm._v(" "),(_vm.errorMsg && _vm.errorMsg.length)?_c('div',{staticClass:"error-message height-fit-content my-3 text-center"},_vm._l((_vm.errorMsg),function(errorObj,index){return _c('p',{key:index},[_vm._v("\n                "+_vm._s(errorObj)+"\n              ")])})):_vm._e(),_vm._v(" "),(
                _vm.bot &&
                  _vm.bot.nlu &&
                  (_vm.bot.nlu.platform === 'luis' ||
                    _vm.bot.nlu.platform === 'watson')
              )?_c('div',{staticClass:"mt-4"},[_c('EngineCredentialsTable',{attrs:{"isEditing":false}})],1):_vm._e()],1)],1)])],1)],1),_vm._v(" "),_c('vs-popup',{attrs:{"title":_vm.lang.surveyMaker.versions.editPopup.title[_vm.languageSelected],"active":_vm.editingVersion.popup},on:{"update:active":function($event){_vm.$set(_vm.editingVersion, "popup", $event)}}},[_c('div',{staticClass:"vx-row"},[_c('vs-col',{attrs:{"vs-justify":"center","vs-align":"center","vs-w":"12"}},[_c('vs-input',{staticClass:"mb-5 w-full",attrs:{"label":_vm.lang.surveyMaker.versions.addPopup.formWizzard.settings.tag
              .labelCompulsory[_vm.languageSelected],"placeholder":_vm.lang.surveyMaker.versions.addPopup.formWizzard.settings.tag
              .placeholder[_vm.languageSelected]},model:{value:(_vm.editingVersion.tag),callback:function ($$v) {_vm.$set(_vm.editingVersion, "tag", $$v)},expression:"editingVersion.tag"}})],1)],1),_vm._v(" "),_c('vs-divider'),_vm._v(" "),_c('div',{staticClass:"vx-row"},[_c('vs-row',[_c('vs-col',{staticClass:"w-full mb-2"},[_c('span',{staticClass:"mb-4",staticStyle:{"font-size":"12px","color":"grey"}},[_vm._v("\n             "+_vm._s(_vm.lang.surveyMaker.versions.addPopup.formWizzard.settings.status
                .label[_vm.languageSelected])+"\n          ")])])],1),_vm._v(" "),_c('vs-row',{staticClass:"mb-0"},[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"center","vs-align":"center","vs-xs":"6","vs-sm":"6","vs-lg":"6"}},[_c('vs-card',{staticClass:"center no-select",class:{ 'active-option': _vm.editingVersion.production },staticStyle:{"cursor":"pointer"},attrs:{"calss":"bg--primary-gradient"}},[_c('div',{on:{"click":function($event){_vm.toggleEditVersionProduction(true)}}},[_c('h3',{class:{ 'active-title': _vm.editingVersion.production }},[_vm._v("\n                "+_vm._s(_vm.lang.surveyMaker['production'][_vm.languageSelected])+"\n              ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('feather-icon',{attrs:{"color":"success","icon":"CheckCircleIcon","svgClasses":"w-8 h-8"}})],1)])],1),_vm._v(" "),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"center","vs-align":"center","vs-xs":"6","vs-sm":"6","vs-lg":"6"}},[_c('vs-card',{staticClass:"center no-select",class:{ 'active-option': !_vm.editingVersion.production },staticStyle:{"cursor":"pointer"},attrs:{"calss":"bg--primary-gradient"}},[_c('div',{on:{"click":function($event){_vm.toggleEditVersionProduction(false)}}},[_c('h3',{class:{ 'active-title': !_vm.editingVersion.production }},[_vm._v("\n                "+_vm._s(_vm.lang.surveyMaker['development'][_vm.languageSelected])+"\n              ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('feather-icon',{attrs:{"color":"success","icon":"Edit3Icon","svgClasses":"w-8 h-8"}})],1)])],1)],1),_vm._v(" "),(_vm.editingVersion.production)?_c('vs-row',[_c('vs-col',{staticClass:"w-full mb-2"},[_c('span',{staticClass:"mb-4 text-danger",staticStyle:{"font-size":"12px"}},[_vm._v("\n            "+_vm._s(_vm.lang.surveyMaker.versions.addPopup.formWizzard.settings.status
                .alert[_vm.languageSelected])+"\n          ")])])],1):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"vx-row"},[_c('vs-row',[_c('vs-col',{staticClass:"mb-2",attrs:{"vs-type":"flex","vs-justify":"space-between","vs-align":"center"}},[_c('vs-button',{attrs:{"color":"danger","type":"flat"},on:{"click":_vm.cancelEditVersion}},[_vm._v("\n            "+_vm._s(_vm.lang.surveyMaker.versions.editPopup.form.cancel[
                _vm.languageSelected
              ])+"\n          ")]),_vm._v(" "),_c('vs-button',{on:{"click":_vm.saveEditVersion}},[_vm._v("\n            "+_vm._s(_vm.lang.surveyMaker.versions.editPopup.form.save[_vm.languageSelected])+"\n          ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }